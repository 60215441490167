import { createI18n } from 'vue-i18n'

// element-plus 中的语言配置
import elementEnLocale from 'element-plus/lib/locale/lang/en'
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'

// 自己的语言配置
import enLocale from './en'
import zhLocale from './zh'

// 语言配置整合
const messages = {
  enLocale,
  zhLocale
}

// 创建 i18n
const i18n = createI18n({
  legacy: false, ///报错信息
  globalInjection:true,  // 全局模式，可以直接使用 $t
  locale: sessionStorage.getItem("lang") || 'enLocale',
  messages: messages
})

export default i18n
