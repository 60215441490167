import { App } from 'vue';
import debounce from '@/utils/debounce';
import throttle from '@/utils/throttle';

/**
 * 注册全局自定义指令
 * @param app
 */
export function setupDirectives(app: App) {
  // 防抖指令
  app.directive('debounce', debounce);
  // 节流指令
  app.directive('throttle', throttle);
}
