import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import adminRoutes from "./admin"
import userRoutes from "./user"
import Home from "../views/Home.vue";
import Login from '@/views/account/login.vue';
import { ref } from "vue";

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    // name: 'Home',
    // redirect: "/login",
    component: Home,
    children: []
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/404",
    name: "404",
    meta: {
      isShow: false,
    },
    component: () => import("@/views/account/404.vue"),
  },
];

let role: any = sessionStorage.getItem("role");
if (role == 0) {
  routes[0].children = adminRoutes
} else if (role == 1 || role == 2 || role ==  3 || role ==  4) {
  routes[0].children = userRoutes
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (token) {
        const flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    let pc = ref('1');
    if (!flag) {
      pc.value = '1';
        sessionStorage.setItem("pc", pc.value);
    } else {
      pc.value = '2';
      sessionStorage.setItem("pc", pc.value);
    }
    if (to.path === "/login") {
      next({
        path: "/home/index",

      });
    } else {
      next();
    }
  } else {
    if (to.path !== "/login" && to.name !== "404") {
      next({
        path: "/login",
      });
    } else {
      next();
    }
    if (to.path !== "/404" && to.name === "404") {
      next({
        path: "/404",
      });
    } else {
      // next();
    }
    return false;
  }
});

router.afterEach((to, from) => {
  // 类型断言
  document.title = to.meta.title as string || '报价系统';
});

export default router;
