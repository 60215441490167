const watermark: { set: (str: string) => void } = {
  set: function (str: string): void {
    throw new Error("Function not implemented.");
  }
};
let BgTextrgba = JSON.parse(JSON.stringify(sessionStorage.getItem('bgcolor')))

let setWatermark = (str: string): string => {
  const id = '1.23452384164.123412415';

  const existingDiv = document.getElementById(id);
  if (existingDiv !== null) {
    document.body.removeChild(existingDiv);
  }

  const can = document.createElement('canvas');
  can.width = 180;
  can.height = 120;


  const cans = can.getContext('2d');
  if (cans !== null) {
    cans.rotate(-20 * Math.PI / 180);
    cans.font = '400 14px Avenir, Helvetica, Arial, sans-serif';
    cans.fillStyle = BgTextrgba;
    cans.textAlign = 'left';
    cans.textBaseline = 'middle';
    cans.fillText(str, can.width / 20, can.height);
  }

  const div = document.createElement('div');
  div.id = id;
  div.style.pointerEvents = 'none';
  div.style.top = '3px';
  div.style.left = '0px';
  div.style.position = 'fixed';
  div.style.zIndex = '1';
  div.style.opacity = '.2';
  div.style.width = '100vw';
  div.style.height = '100vh';
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
  document.body.appendChild(div);
  return id;
};

// 该方法只允许调用一次
watermark.set = (str: string): void => {
  let id = setWatermark(str);
  const existingDiv = document.getElementById(id);
  if (existingDiv === null) {
    id = setWatermark(str);
  }
  window.onresize = () => {
    setWatermark(str);
  };
};

export default watermark;
