
/// 普通用户路由
import Home from "../views/Home.vue";
const userRoutes = [
      {
        path: "/home/index",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "控制台",
          icon: "HomeFilled",
          isShow: true,
        },
      },
      {
        path: "/table/useTable",
        name: "table",
        meta: {
          icon: "MessageBox",
          title: "报价管理",
          isShow: true,
        },
        children: [
          {
            path: "/table/useTable/index",
            name: "useTable",
            component: () => import("@/views/table/useTable/index.vue"),
            meta: {
              icon: "Histogram",
              title: "设备价格查阅",
              // isShow: true,
              isHide: false,
            },
          },
          {
            path: "/table/index",
            name: "contact",
            component: () => import("@/views/table/index.vue"),
            meta: {
              icon: "TrendCharts",
              title: "需求填写",
              // isShow: true,
              isHide: false,

            }
          },
        ],
      },
      {
        path: "/form/useForm",
        name: "form",
        meta: {
          icon: "Tools",
          title: "系统管理",
          isShow: true,
        },
        children: [
          {
            path: "/form/useForm/index",
            name: "useForm",
            component: () => import("@/views/form/useForm/index.vue"),
            meta: {
              icon: "UserFilled",
              title: "个人资料",
              // isShow: true,
              isHide: false,
            },
          },
        ],
      },
]

export default userRoutes