export default {
    login: {
        login: '登录',
        quotation: '砂石-销售询报价系统',
        user: '账户',
        password: '密码',
        logins: '登录',
        online: "在线",
        offer: "报价系统"
    },
    routers:{
        logOut:'退出登录',
        控制台:'控制台',
        报价管理:'报价管理',
        设备价格查阅:'设备价格查阅',
        需求填写:'需求填写',
        表单列表: '表单列表',
        系统管理:'系统管理',
        个人资料:'个人资料',
        用户列表:'用户列表',
        产品结构: '产品结构',
        汇率: '汇率',

        SNI:'系统通知信息: 共',
        SNImessages: '条',

    },
    label: {
         snotifications: '系统通知',
         inotifications: '询价通知',
    },
    table: {
        notification: '添加通知',
        Serial:'序号',
        Title: '通知标题',
        content:'通知内容',
        time: '发布时间',
        action: '操作',
        detail: '详情',
        edit: '编辑',
        detele: '删除',
        progress: '最近30天内询价进程信息：共',
        entries: '条',
        save: '保存',
        cancel: '取消'
    },
    offer: {
        category: "价格分类",
        sou: "搜索",
        add: "添加产品",
        search: "查询",
        close: "关闭",
        view:"查看",
        code:"物料编码(代号)",
        name: "物料名称(产品型号)",
        photo_num: "物料图号",
        version_num:"物料版本号",
        power:"功率（P-KW）",
        price:"价格分类",
        price1: "价格（美元）",
        detail:"详情说明",
        data: "暂无数据",
        Level1:"一级权限价格",
        Level2:"二级权限价格",
        Level3: "三级权限价格",
        Level4: "四级权限价格",
        pro: "修改产品",
        zui: "最低价格",
        gao: "最高价格",
        can: "设备参数",
        zan: "暂无参数",
        tuji: "图集",
        ptype: "产品类型"
    },
    user: {
        username: "用户名",
        nickname: "昵称",
        Information: "修改信息",
        resat: "修改密码",
        User: "添加用户",
        submit: "提交",
        cancel: "取消",
        new: "新密码",
        old: "旧密码"
    },
    userList: {
        moble: "手机号",
        email: "邮箱",
        level: "权限等级",
        time:"创建时间",
        Subcategory: "添加子分类",
        Category: "删除产品分类",
        edit: "修改产品分类",
        add: "添加",
        name: "类名称：",
        shu: "数据加载中..."
    },
    form: {
        name: "姓名",
        phone:"联系方式",
        email:"邮箱",
        image: "参考图片",
        Information: "需求描述",
        dispose: "处理",
        can: "主要参数",
        clear: "清空",
        time: "时间",
        chu: "未处理",
        li: "已处理",
        titime: "提交时间",
        sttime: "开始时间",
        endtime: "结束时间",
        rate: "汇率",
        agency: "代理价格",
        jsdi: "整机国贸报价",
        currency: "人名币",
        ai: "矮油~~您访问的页面不在地球上...",
        fan: "返回登录页面"

    }
}
