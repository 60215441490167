import axios, { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import Router from '@/router/index'
import {apiUrl} from '@/config/constant'
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' //样式必须引入
import { ElMessage } from "element-plus";
import qs from 'qs'

const service = axios.create({
    baseURL: apiUrl,
    timeout: 30000,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
        // "xhr.withCredentials": true  // 前端开关:浏览器是否读写cookie
    },
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem("token")
        if (token) {
            config.headers.token = token
        }
        NProgress.start()
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

//响应拦截
// 添加响应拦截器
service.interceptors.response.use(
    // 处理响应数据
    response => {
        if (response.data.code === 20000) {
            NProgress.done()
            return response.data
        } else if (response.data.code === 40300) {
            ElMessage.error(response.data.errMsg)
            NProgress.done()
        } else if (response.data.code === 401) {
            ElMessage.error(response.data.errMsg)
            NProgress.done()
            sessionStorage.clear()
            setTimeout(function () {
                Router.go(0);
            }, 2000)
        } else if (response.data.code === 404) {
            ElMessage.error(response.data.errMsg)
            NProgress.done()
            sessionStorage.clear()
            Router.replace('/404')
        } else {
            NProgress.done()
            return Promise.reject(response.data)
        }
    },
    // 处理错误
    error => {
        if (error.response) {
            const code = error.response.status || 404
            if (code === 500) {
                ElMessage.error(error.response.data.msg)
                // Router.replace('/login')
            }
        }
        return Promise.reject(error)
    }
)

export default {
    get(url:any, params = {}) {
        return service.get(url, { params }).then(res => res)
    },
    post(url:any, params = {}) {
        return service.post(url, params).then(res => res)
    }
}
